<!--

© 2020 – 2021 ProCSy JSC https://procsy.ru info@procsy.ru

© АО «ПроКСи», 2020 – 2021 info@procsy.ru

-->


<template>
  <CRow>
    <CCol>
      <CCard>
        <CCardHeader>
          <div class='row'>
            <div class='col-sm'>
              <strong>{{ nameByType }} {{ endpoint.hostname }}</strong>
            </div>
          </div>
        </CCardHeader>
        <CCardBody>
          <CRow class='object-card-row'>
            <CCol sm='3' class='font-weight-bold'>{{ $t('service.state') }}</CCol>
            <CCol sm='9'>
              <span class='ml-2'></span>
            </CCol>
          </CRow>
          <CRow class='object-card-row'>
            <CCol sm='3' class='font-weight-bold'>{{ $t('service.state') }}</CCol>
            <CCol sm='9'>
              <span class='ml-2'></span>
            </CCol>
          </CRow>

        </CCardBody>
      </CCard>
    </CCol>
  </CRow>
</template>

<script>
import utilMessages from '../../../../utils/messages';

export default {
  name: 'Endpoint',
  data() {
    return {
      endpointId: null,
      endpoint: {
        type: null,
        hostname: null
      }
    };
  },

  async mounted() {
    if (this.$route.params.id) {
      this.endpointId = this.$route.params.id;
      await this.getEndpoint();
    }
  },

  computed: {
    nameByType() {
      switch (this.endpoint.type) {
        case 'peer':
          return 'Peer';
        case 'orderer':
          return 'Orderer';
        case 'fabric-admin-tools':
          return 'Fabric admin tools';
        default:
          return '';
      }
    }
  },

  methods: {
    async getEndpoint() {
      try {
        this.endpoint = await this.$store.dispatch('getEndpointById', this.endpointId);
        console.log('this endpoint', this.endpoint);
      } catch (err) {
        this.$toast.error(utilMessages.errMessage(err));
      }
    }

  }
};
</script>
<style>
</style>
